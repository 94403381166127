<template>
  <div>
    <div
      class="user-header flex h-[4.5rem] shrink-0 items-center justify-between space-x-6 border-b px-6 text-sm font-medium transition-all"
      :class="{ 'lg:-mt-[4.5rem]': minimalUI }"
    >
      <div class="flex items-center">
        <!-- <a @click="toggleSidebar" class="mr-6 block cursor-pointer text-paragraph hover:text-primary lg:hidden">
          <Icon icon="bars" prefix="far" class="text-base" />
        </a> -->
        <nav class="flex items-center space-x-6">
          <NuxtLink to="/experiences">
            <img class="block h-6 w-6" src="/ag-avatar.svg" alt="Approach Guides" />
          </NuxtLink>
          <div v-if="!$route.meta.hideNav && !isLoggedIn" class="hidden lg:flex lg:items-center lg:space-x-6">
            <NuxtLink to="/" class="user-header__link">Home</NuxtLink>
            <NuxtLink to="/experiences" class="user-header__link">Experiences</NuxtLink>
          </div>
        </nav>
      </div>

      <div class="flex items-center space-x-8">
        <ProfileStatus v-if="user?.profile_completion_percentage !== 100" />
        <UserMenu />
      </div>
    </div>
    <slot />
  </div>
</template>

<script setup lang="ts">
const { isLoggedIn, user } = useAuth();
const { showMobileSidebar, minimalUI } = useLayout();
const toggleSidebar = () => (showMobileSidebar.value = !showMobileSidebar.value);
</script>

<style lang="postcss">
.user-header {
  &__link {
    @apply flex h-[4.5rem] items-center border-y-2 border-transparent font-semibold text-gray-500 hover:text-primary;

    &.router-link-exact-active {
      @apply border-b-primary text-primary;
    }
  }
}
</style>
