<template>
  <div class="flex grow flex-col overflow-hidden">
    <div
      class="fixed inset-0 z-50 flex w-full flex-shrink-0 transform flex-col text-sm transition lg:hidden"
      :class="showMobileSidebar ? 'translate-x-0' : '-translate-x-full'"
    >
      <MobileSidebar />
    </div>
    <div class="fixed inset-0 flex grow flex-col transition-spacing">
      <AppHeader>
        <slot name="header-content" />
      </AppHeader>
      <div class="flex grow flex-col overflow-y-auto">
        <div class="flex grow flex-col">
          <slot />
        </div>
        <div v-if="!hideFooter" class="shrink-0">
          <AppFooter />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const { showMobileSidebar } = useLayout();

defineProps<{
  hideFooter?: boolean;
}>();
</script>
