<template>
  <Dropdown v-if="user" :ui="{ items: 'w-80 right-auto -translate-x-1/2 md:right-0 md:translate-x-0' }" class="mt-2 md:mt-0">
    <template #label>
      <div class="mr-1.5 flex items-center space-x-4">
        <div class="flex items-center space-x-2.5">
          <Icon icon="sparkles" prefix="far" />
          <span class="hidden md:block"
            >Complete Your Profile <span class="text-sm text-supplement">({{ user.profile_completion_percentage }}%)</span></span
          >
        </div>
        <div class="relative h-2 w-10 overflow-hidden rounded-full">
          <div class="absolute inset-0 bg-gray-200" />
          <div class="absolute inset-y-0 left-0 bg-pink-600" :style="{ width: `${user.profile_completion_percentage}%` }" />
        </div>
      </div>
    </template>

    <div class="p-6">
      <p><NuxtLink to="/profile">Update your profile</NuxtLink> to provide your guests with the best possible content experiences.</p>
      <div class="mt-4 space-y-1.5">
        <div v-for="(completed, task) in user.profile_tasks" class="flex items-center space-x-3">
          <div class="grid size-4 shrink-0 place-items-center">
            <Icon v-if="completed" icon="check" class="text-green-600" />
            <div v-else class="size-3.5 rounded border border-input" />
          </div>
          <div>{{ task }}</div>
        </div>
      </div>
      <p class="mt-6"><Button to="/profile" full-width prepend-icon="pencil" size="sm">Update Profile</Button></p>
    </div>
  </Dropdown>
</template>

<script setup lang="ts">
const user = useUser();
</script>
