<template>
  <Band as="footer" :ui="{ base: 'text-center border-t p-8 md:p-10 lg:p-10' }">
    <div class="md:flex md:justify-center">
      <NuxtLink to="/" class="inline-block">
        <img class="inline-block h-6 w-6" src="/ag-avatar.svg" alt="Approach Guides" />
      </NuxtLink>

      <div class="mt-8 grid grid-cols-1 gap-6 md:ml-2 md:mt-0">
        <div class="md:flex md:items-center">
          <NuxtLink
            href="https://www.approachguides.com/about"
            target="_blank"
            class="flex justify-center pb-1.5 font-medium text-paragraph hover:text-primary md:px-4 md:pb-0"
          >
            <span>About</span>
          </NuxtLink>
          <span class="hidden md:flex md:items-center">
            <Icon icon="circle" class="mt-0.5 text-[0.3rem] text-gray-300" />
          </span>
          <NuxtLink
            href="https://www.approachguides.com/contact"
            target="_blank"
            class="flex justify-center py-1.5 font-medium text-paragraph hover:text-primary md:px-4 md:py-0"
          >
            <span>Contact</span>
          </NuxtLink>
          <span class="hidden md:flex md:items-center">
            <Icon icon="circle" class="mt-0.5 text-[0.3rem] text-gray-300" />
          </span>
          <NuxtLink
            href="https://approachguides.com/co-brand"
            target="_blank"
            class="flex justify-center pt-1.5 font-medium text-paragraph hover:text-primary md:px-4 md:py-0"
          >
            <span>For travel brands</span>
          </NuxtLink>
        </div>
      </div>
    </div>

    <div class="mt-8 text-center text-sm text-supplement md:mt-3 md:flex md:justify-center md:divide-x">
      <p>
        &copy; 2023-{{ new Date().getFullYear() }}
        <NuxtLink href="https://www.approachguides.com" target="_blank" class="text-supplement hover:text-paragraph">
          <span>Approach Guides</span>
        </NuxtLink>
      </p>
      <div class="mt-2 inline-flex divide-x md:ml-3 md:mt-0">
        <NuxtLink to="/cookies" class="flex px-3 text-supplement hover:text-paragraph">
          <span>Cookies</span>
        </NuxtLink>
        <NuxtLink href="https://www.approachguides.com/privacy" target="_blank" class="flex px-3 text-supplement hover:text-paragraph">
          <span>Privacy</span>
        </NuxtLink>
        <NuxtLink href="https://www.approachguides.com/terms" target="_blank" class="flex px-3 text-supplement hover:text-paragraph">
          <span>Terms</span>
        </NuxtLink>
      </div>
    </div>
  </Band>
</template>
<script setup lang="ts"></script>
