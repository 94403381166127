<template>
  <aside class="mobile-sidebar flex grow flex-col bg-white">
    <header class="flex items-center justify-between p-8">
      <div>
        <NuxtLink :to="isLoggedIn ? '/experiences' : '/'" @click="closeSidebar">
          <img class="block h-6 w-6" src="/ag-avatar.svg" alt="Approach Guides" />
        </NuxtLink>
      </div>
      <button @click="showMobileSidebar = false" class="-mr-2 h-8 w-8 lg:hidden">
        <Icon icon="times" />
      </button>
    </header>
    <main class="flex grow items-center">
      <nav class="flex flex-col">
        <Guest>
          <NuxtLink to="/" class="mobile-sidebar__link" @click="closeSidebar">Home </NuxtLink>
        </Guest>
        <NuxtLink to="/experiences" class="mobile-sidebar__link" @click="closeSidebar">Experiences </NuxtLink>
      </nav>
    </main>
    <footer class="p-8 text-supplement">
      <p>&copy; Approach Guides. All rights reserved.</p>
    </footer>
  </aside>
</template>

<script setup lang="ts">
const { isLoggedIn } = useAuth();
const { showMobileSidebar } = useLayout();

const closeSidebar = () => (showMobileSidebar.value = false);
</script>

<style lang="postcss">
.mobile-sidebar {
  &__link {
    @apply relative px-8 py-3;

    &:before {
      @apply absolute inset-y-0 left-0 w-[2px];
      content: "";
    }

    &.router-link-exact-active:before {
      @apply bg-primary;
    }
  }
}
</style>
